import { useCallback, useEffect, useState } from 'react';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useToggleUserBlockedTermsAccepted } from '../../../hooks/useToggleUserBlockedTermsAccepted';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { TOGGLE_USER_BLOCKED_TERMS_ACCEPTED_AT_QUERY } from '../../../queries/toggleUserBlockedTermsAccepted.query';

import { BlockedWorkerTermsAcceptModalContent } from './content/BlockedWorkerTermsAcceptModalContent';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';

import { words } from '../../../../../locales/keys';

function BlockedWorkerTermsAcceptModal() {
  const currentUser = useCurrentUser();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = useCallback<() => void>(() => {
    setIsModalVisible(false);
  }, []);

  const handleShowModal = useCallback<() => void>(() => {
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    if (
      currentUser?.blocked &&
      !currentUser?.blockedTermsAcceptedAt &&
      !currentUser?.client
    ) {
      handleShowModal();
    }
  }, [
    handleShowModal,
    currentUser?.blocked,
    currentUser?.blockedTermsAcceptedAt,
    currentUser?.client
  ]);

  const {
    toggleUserBlockedTermsAccepted,
    toggleUserBlockedTermsAcceptedErrorMessage,
    toggleUserBlockedTermsAcceptedLoading
  } = useToggleUserBlockedTermsAccepted({
    query: TOGGLE_USER_BLOCKED_TERMS_ACCEPTED_AT_QUERY
  });

  const [accepted, setAccepted] = useState<boolean>(
    !!currentUser?.blockedTermsAcceptedAt
  );

  useShowToastOnErrorChange({
    error: toggleUserBlockedTermsAcceptedErrorMessage
  });

  const handleClose = useCallback<() => void>(async () => {
    await toggleUserBlockedTermsAccepted({ uuid: currentUser.uuid });

    handleCloseModal();
  }, [currentUser.uuid, toggleUserBlockedTermsAccepted, handleCloseModal]);

  const toggleAccepted = useCallback<() => void>(() => {
    setAccepted((prevValue) => !prevValue);
  }, []);

  return (
    <SimpleModal
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      handleSubmit={handleClose}
      hideModal={handleShowModal}
      i18nSubmitText={words.accept}
      id="TermsAcceptModal"
      isLoading={toggleUserBlockedTermsAcceptedLoading}
      isOpen={isModalVisible}
      modalSize="3xl"
      submitDisabled={!accepted}
      withoutCancelButton
    >
      <BlockedWorkerTermsAcceptModalContent
        accepted={accepted}
        onToggleAccepted={toggleAccepted}
        isLoading={toggleUserBlockedTermsAcceptedLoading}
      />
    </SimpleModal>
  );
}

export default BlockedWorkerTermsAcceptModal;

import { IsLoading } from '../../../../../../../types';
import { PageNanoID } from '../../../../../../pages/pagesTypes';

import { usePageShowPage } from '../../../../../../pages/hooks/usePageShowPage';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../../../pages/queries/fetchPageShowPage.query';

import { CheckBoxField } from '../../../../../../../helpers/FormFields/CheckBoxField';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../helpers/Translate';

import { NOTIFICATION_OF_TEMPORARY_BLOCKING_PAGE_NANOID } from '../../../../../../../config';

import { PageCache } from '../../../../../../pages/PageCache';

import { usersKeys } from '../../../../../../../locales/keys';

interface BlockedWorkerTermsAcceptModalContentProps {
  accepted: boolean;
  isLoading?: IsLoading;
  onToggleAccepted: () => void;
}

function BlockedWorkerTermsAcceptModalContent({
  accepted,
  isLoading,
  onToggleAccepted
}: BlockedWorkerTermsAcceptModalContentProps) {
  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: NOTIFICATION_OF_TEMPORARY_BLOCKING_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(
        NOTIFICATION_OF_TEMPORARY_BLOCKING_PAGE_NANOID as PageNanoID
      ),
      initFetch: true
    }
  );

  return (
    <>
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          {page?.localizedName}
        </h3>
      </div>

      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />

          <div className="flex space-x-4">
            <CheckBoxField
              id="accepted"
              name="accepted"
              checked={accepted}
              onChange={onToggleAccepted}
              disabled={isLoading}
            />
            <span>
              <Translate
                id={
                  usersKeys.iConfirmThatIHaveReviewedTheImportantPointsAndAgreeToComplyWithThem
                }
              />
            </span>
          </div>
        </div>
      </LoadingSkeleton>
    </>
  );
}

export default BlockedWorkerTermsAcceptModalContent;

import { gql } from 'graphql-request';
import {
  UserBlockedTermsAcceptedAt,
  UserNanoID,
  UserUUID
} from '../usersTypes';

export interface ToggleUserBlockedTermsAcceptedQueryResponse {
  uuid: UserUUID;
  nanoId: UserNanoID;
  blockedTermsAcceptedAt: UserBlockedTermsAcceptedAt;
}

export const TOGGLE_USER_BLOCKED_TERMS_ACCEPTED_AT_QUERY = gql`
  mutation ToggleUserBlockedTermsAccepted($uuid: ID!) {
    toggleUserBlockedTermsAccepted(input: { uuid: $uuid }) {
      status
      recordUuid
      record {
        uuid
        nanoId
        termsAcceptedAt
      }
      errors {
        fullMessages
      }
    }
  }
`;

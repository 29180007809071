import { useCallback, useEffect, useState } from 'react';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useFeedBackForm } from '../../FeedBackForm/hooks/useFeedBackForm';

import { FeedBackForm } from '../../FeedBackForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';

import { stringsKeys, words } from '../../../../../locales/keys';

const CONTACT_SUPPORT_MODAL = 'contact-support-modal';
const CONTACT_SUPPORT_FORM = 'contact-support-form';

function BlockedClientFeedBackModal() {
  const currentUser = useCurrentUser();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = useCallback<() => void>(() => {
    setIsModalVisible(false);
  }, []);

  const handleShowModal = useCallback<() => void>(() => {
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    if (currentUser?.blocked && currentUser?.client) {
      handleShowModal();
    }
  }, [handleShowModal, currentUser?.blocked, currentUser?.client]);

  const {
    registerFields,
    createFeedBackLoading,
    createFeedBackErrorMessage,
    createFeedBackReset,
    handleSubmitCreateFeedBack,
    validationErrors
  } = useFeedBackForm({ isAuthenticated: true });

  return (
    <SimpleModal
      id={CONTACT_SUPPORT_MODAL}
      isOpen={isModalVisible}
      i18nSubmitText={words.send}
      i18nTitle={words.contactSupport}
      disabled={createFeedBackLoading}
      handleSubmit={handleSubmitCreateFeedBack}
      hideModal={handleCloseModal}
      onCancel={createFeedBackReset}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
    >
      <FeedBackForm
        disabled={createFeedBackLoading}
        form={CONTACT_SUPPORT_FORM}
        isAuthenticated={true}
        registerBody={registerFields.registerBody}
        registerEmail={registerFields.registerEmail}
        registerName={registerFields.registerName}
        registerSubject={registerFields.registerSubject}
        validationErrorBody={validationErrors.feedBackBodyValidationError}
        validationErrorEmail={validationErrors.feedBackEmailValidationError}
        validationErrorName={validationErrors.feedBackNameValidationError}
        validationErrorSubject={validationErrors.feedBackSubjectValidationError}
        warningI18nText={
          currentUser?.blocked && currentUser?.client
            ? stringsKeys.thisAccountHasBeenDeletedPleaseContactSupport
            : null
        }
      />
      <AlertMessage addClassName="mt-4" message={createFeedBackErrorMessage} />
    </SimpleModal>
  );
}

export default BlockedClientFeedBackModal;

import { ReactNode } from 'react';

import { FetchItemError, FetchItemsError, I18nText } from '../../../../types';

import { useThirdPersonView } from '../../../../app/hooks/useThirdPersonView';

import { BlockedClientFeedBackModal } from '../../../feedBacks/components/modals/BlockedClientFeedBackModal';
import { BlockedWorkerTermsAcceptModal } from '../../../users/components/modals/BlockedWorkerTermsAcceptModal';
import { CheckErrorLayout } from '../../../../common/layouts/CheckErrorLayout';
import { CheckPermissionLayout } from '../../../../common/layouts/CheckPermissionLayout';
import { DefaultFooter } from '../../components/footers/DefaultFooter';
import { DownloadManagerCornerModal } from '../../components/footers/DownloadManagerCornerModal';
import { GlobalNotification } from '../../components/footers/GlobalNotification';
import { TermsAcceptModal } from '../../../users/components/modals/TermsAcceptModal';
import { WelcomeInfoModal } from '../../components/footers/WelcomeInfoModal';
import { WelcomeModal } from '../../components/footers/WelcomeModal';

import { HeadTitle } from '../../../../helpers/HeadTitle';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { Permissions } from '../../../../common/permissions';
import { UsersPermissions } from '../../../users/usersConstants';

interface MainLayoutProps {
  action: Permissions | string;
  withoutActionError?: boolean;
  children: ReactNode;
  i18nTitle?: I18nText;
  title?: string;
  error?: FetchItemError | FetchItemsError;
}

function MainLayout({
  action,
  withoutActionError,
  i18nTitle,
  title,
  children,
  error
}: MainLayoutProps) {
  const { isThirdPersonView } = useThirdPersonView();

  return (
    <CheckErrorLayout error={error}>
      <CheckPermissions
        action={UsersPermissions.READ_BLOCKED_CLIENT_NOTIFICATION}
      >
        <BlockedClientFeedBackModal />
      </CheckPermissions>

      <CheckPermissions
        action={UsersPermissions.READ_BLOCKED_WORKER_NOTIFICATION}
      >
        <BlockedWorkerTermsAcceptModal />
      </CheckPermissions>

      <CheckPermissionLayout
        action={action}
        appAction={Permissions.MAIN_ACCESS}
        withoutActionError={withoutActionError}
      >
        {i18nTitle ? <HeadTitle i18nTitle={i18nTitle} /> : null}
        {title ? <HeadTitle i18nTitle={title} /> : null}

        <div
          id="main-section"
          className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
        >
          {children}

          <CheckPermissions
            action={UsersPermissions.READ_SELF_GLOBAL_NOTIFICATION}
          >
            <GlobalNotification />
          </CheckPermissions>

          <CheckPermissions action={UsersPermissions.READ_SELF_WELCOME_WINDOW}>
            <WelcomeModal />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_SELF_WELCOME_INFO_WINDOW}
          >
            <WelcomeInfoModal />
          </CheckPermissions>

          <CheckPermissions
            action={UsersPermissions.READ_SELF_LEGAL_INFORMATION_UPDATE}
          >
            <TermsAcceptModal />
          </CheckPermissions>

          {isThirdPersonView ? null : (
            <>
              <DownloadManagerCornerModal />
              <DefaultFooter action={action} />
            </>
          )}
        </div>
      </CheckPermissionLayout>
    </CheckErrorLayout>
  );
}

export default MainLayout;

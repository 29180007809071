import { IconsEnum } from '../../../../../assets/icons/types';

import { AddFundsModalButton } from '../../../../invoices/components/modalButtons/AddFundsModalButton';
import { ShowProjectTourButton } from '../../../../tours/components/buttons/ShowProjectTourButton';
import { UserDownloadManagerCornerButton } from '../../modalButtons/UserDownloadManagerCornerButton';
import { UserFinanceDebitBadgeLink } from './components/UserFinanceDebitBadgeLink';
import { UserFinanceDebitFinBadgeLink } from './components/UserFinanceDebitFinBadgeLink';
import { UserTermsModalButton } from '../../../../../app/components/modalButtons/UserTermsModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { AppPermissions } from '../../../../../app/appConstants';
import { InvoicesPermissions } from '../../../../invoices/invoicesConstants';
import { Permissions } from '../../../../../common/permissions';
import { ProjectsPermissions } from '../../../../projects/projectsConstants';
import { UsersPermissions } from '../../../../users/usersConstants';

import { invoicesKeys, words } from '../../../../../locales/keys';

interface DefaultFooterProps {
  action: Permissions | string;
}

function DefaultFooter({ action }: DefaultFooterProps) {
  return (
    <div className="relative -z-1 w-full border-t border-gray-200 dark:border-gray-700 text-xs leading-6 flex justify-between text-gray-700 dark:text-gray-300 -mb-px">
      <div className="flex">
        <CheckPermissions
          action={UsersPermissions.READ_USER_FINANCE_DEBIT_BADGE_LINK}
        >
          <UserFinanceDebitBadgeLink />
        </CheckPermissions>

        <CheckPermissions
          action={UsersPermissions.READ_USER_FINANCE_DEBIT_FIN_BADGE_LINK}
        >
          <UserFinanceDebitFinBadgeLink />
        </CheckPermissions>

        <CheckPermissions
          action={InvoicesPermissions.READ_FOOTER_ADD_FUNDS_MODAL_BUTTON}
        >
          <AddFundsModalButton
            className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white hidden sm:flex items-center gap-1.5 mb-px"
            icon={IconsEnum.PLUS}
            i18nText={invoicesKeys.addFunds}
          />
        </CheckPermissions>

        {action === ProjectsPermissions.READ_PROJECT_MESSAGES_PAGE && (
          <CheckPermissions
            action={ProjectsPermissions.READ_PROJECT_ONBOARDING_TOUR}
          >
            <ShowProjectTourButton />
          </CheckPermissions>
        )}
      </div>

      <div className="flex">
        <CheckPermissions action={AppPermissions.READ_DOWNLOADS_MODAL_BUTTON}>
          <UserDownloadManagerCornerButton />
        </CheckPermissions>

        <CheckPermissions action={AppPermissions.READ_TERMS_MODAL_BUTTON}>
          <UserTermsModalButton
            i18nText={words.terms}
            i18nTextClassName="leading-6 2xl:leading-8 font-medium text-blue-600 hover:text-blue-500 hidden sm:flex"
            className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white flex items-center gap-1.5 mb-px"
          />
        </CheckPermissions>
      </div>
    </div>
  );
}

export default DefaultFooter;
